import React, { useEffect } from "react";
import { FirebaseContext } from "../components/Firebase";

export default function useDocument(collectionId, id) {
  // initialize our default state
  const firebase = React.useContext(FirebaseContext);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [document, setDocument] = React.useState(null);
  // when the id attribute changes (including mount)
  // subscribe to the recipe document and update
  // our state when it changes.
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(collectionId)
      .doc(id)
      .onSnapshot(
        doc => {
          setLoading(false);
          setDocument(doc.data());
        },
        err => {
          setError(err);
        }
      );
    // returning the unsubscribe function will ensure that
    // we unsubscribe from document changes when our id
    // changes to a different value.
    return () => unsubscribe();
  }, [collectionId, firebase, id]);

  return {
    error,
    loading,
    document
  };
}
