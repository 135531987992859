import React from "react";
import styled from "styled-components";

export default function Faq({ question, children, isOpen, setOpenFaq }) {
  return (
    <Container onClick={setOpenFaq}>
      <Question>
        <p> {question} </p>
        <img
          src={require("../assets/arrow-down.png")}
          className={isOpen ? "open" : ""}
          alt="arrow"
        />
      </Question>
      <Answer className={isOpen ? "open" : ""}>
        <div dangerouslySetInnerHTML={{ __html: children }} />
      </Answer>
    </Container>
  );
}

const Container = styled.div`
  padding: 3rem 0px;
  border-bottom: solid rgba(63, 63, 63, 0.1);
  border-width: 2px;
  transition: transform 1s;
  cursor: pointer;

  &:first-child {
    padding-top: 1.5rem;
  }
`;

const Question = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;

  img {
    height: 15px;
    transition: all 0.2s ease-in-out;
  }

  img.open {
    transition: all 0.3s ease-in-out;
    transform: rotateX(180deg);
  }
`;

const Answer = styled.div`
  color: #929292;
  font-size: 1.75rem;
  line-height: 3.3rem;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  &.open {
    margin-top: 2.5rem;
    height: auto;
    max-height: 105rem;
    transition: max-height 1s;
  }
`;
