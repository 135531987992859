import React, { useState } from "react";
import Faq from "./Faq";

export default function QuestionList({ faqs }) {
  const [openFaq, setCurrentFaq] = useState(null);

  const setOpenFaq = index => {
    if (index === openFaq) {
      setCurrentFaq(null);
    } else {
      setCurrentFaq(index);
    }
  };

  return (
    <>
      {faqs.map(faq => {
        const index = faqs.findIndex(
          faqSearch => faq.question === faqSearch.question
        );
        return (
          <Faq
            isOpen={index === openFaq}
            key={index}
            setOpenFaq={() => {
              setOpenFaq(index);
            }}
            question={faq.question}
          >
            {faq.answer}
          </Faq>
        );
      })}
    </>
  );
}
