import app from "firebase/app";
import "firebase/firestore";

const config = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_ID
  apiKey: "AIzaSyDtf15N6y2y7UuKA_YjdTMyRhkWtuT6Exc",
  authDomain: "motivapp-530bf.firebaseapp.com",
  databaseURL: "https://motivapp-530bf.firebaseio.com",
  projectId: "motivapp-530bf",
  storageBucket: "motivapp-530bf.appspot.com",
  messagingSenderId: "891865867931",
  appId: "1:891865867931:web:1c3f75751fa76cb8",
  measurementId: "G-1VFHK9RC99"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.firestore = app.firestore;
  }
}

export default Firebase;
