import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useDocument from "../hooks/useDocument";

export default function Navigator({ match }) {
  const { document: termsDocument, loading: termsLoading } = useDocument("dynamic-content", 'terms');

  return (
    <>
      {!termsLoading && termsDocument && <Container>
      <p className="label">SABER MÁS</p>
      <StyledLink to="/faq" active={+(match.path === "/faq")}>
        Preguntas Frecuentes
      </StyledLink>
      <p className="label" style={{ marginTop: "3.5rem" }}>
        POLÍTICAS
      </p>
      <StyledLink to="/privacy" active={+(match.path === "/privacy")}>
        {termsDocument.privacyPoliciesTitle}
      </StyledLink>
      <StyledLink to="/terms" active={+(match.path === "/terms")}>
        {termsDocument.useTermsTitle}
      </StyledLink>
    </Container>}
    </>
  );
}

const Container = styled.div`
  display: inline-block;
  padding: 3.5rem;
  border-radius: 4px;
  border: solid rgba(63, 63, 63, 0.1);
  border-width: 1.5px;
  display: inline-block;
  width: 100%;
  font-weight: bold;

  /* Modificación de width del contenedor cuando sube */
  @media (max-width: 1200px) {
    width: 85%;
  }
  @media (max-width: 772px) {
    width: 90%;
  }
  /* Modificación del padding que se descontrola */
  @media (max-width: 474px) {
    padding: 2rem;
  }

  .label {
    color: #3f3f3f;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.075rem;
    @media (max-width: 800px) {
      font-size: 2.5rem;
    }
  }

  /* Modificación de width del contenedor cuando sube */
  @media (max-width: 800px) {
    font-size: 3.25rem;
  }
  /* Modificación del padding que se descontrola */
  @media (max-width: 474px) {
    font-size: 2rem;
  }
`;

const StyledLink = styled(Link)`
  color: ${props => (props.active ? "#00aeef" : "#e3e3e3")};
  font-size: 2rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  display: block;

  &:last-child {
    margin: 0px;
  }

  /* Modificación de width del contenedor cuando sube */
  @media (max-width: 800px) {
    font-size: 3.25rem;
  }
  /* Modificación del padding que se descontrola */
  @media (max-width: 474px) {
    font-size: 2rem;
  }
`;
