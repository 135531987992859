import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QuestionList from "./QuestionList";
import useDocument from "../hooks/useDocument";

export default function InformationSection({ match }) {
  const [title, setTitle] = useState(null);
  const { document: termsDocument, loading: termsLoading } = useDocument("dynamic-content", 'terms');
  const { document: faqDocument, loading: faqLoading } = useDocument("dynamic-content", 'faq');
  
  // Cambios de seccion
  useEffect(() => {
    let newTitle;

    if (match.path && !termsLoading && termsDocument) {
      switch (match.path) {
        case "/faq":
          newTitle = "Preguntas Frecuentes";
          break;
        case "/privacy":
          newTitle = termsDocument.privacyPoliciesTitle;
          break;
        case "/terms":
          newTitle = termsDocument.useTermsTitle;
          break;
        default:
          throw new Error("unexisting title");
      }
      setTitle(newTitle);
      window.parent.postMessage(match.path, "*");
    }
  }, [match.path, termsDocument, termsLoading]);

  return (
    <>
      {match.path && !faqLoading && !termsLoading && termsDocument && faqDocument && (
        <div>
          <Title>{title}</Title>
          <InfoContainer>
            {match.path === "/faq" && (
              <QuestionList faqs={faqDocument.faqs} />
            )}
            {match.path !== "/faq" && (
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      match.path === "/privacy"
                        ? termsDocument.privacyPoliciesContent
                        : termsDocument.useTermsContent
                  }}
                />
              </Text>
            )}
          </InfoContainer>
        </div>
      )}
    </>
  );
}

const Title = styled.h1`
  font-size: 3.8rem;
  color: #00aeef;

  /* Margen encima del Título para responsive debajo del nav */
  @media (max-width: 772px) {
    margin-top: 3.5rem;
  }

  /* Font sizes para evitar wrap */
  @media (max-width: 474px) {
    font-size: 3rem;
  }

  @media (max-width: 348px) {
    font-size: 2.5rem;
  }
`;

const Text = styled.div`
  font-size: 1.75rem;
  line-height: 3.3rem;
  color: #929292;
`;

const InfoContainer = styled.div`
  padding: 2rem 3rem;

  @media (max-width: 474px) {
    padding: 0rem;
  }

  li {
    margin: 2rem;
  }
`;
