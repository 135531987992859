// Styles Components
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Open Sans', sans-serif;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

::-webkit-scrollbar { 
    display: none; 
}

html {
  font-size: 62.5%;

  @media (max-width: 1500px) {
    font-size: 56.5%;
  }

  @media (max-width: 1084px) {
    font-size: 55%;
  }

  @media (max-width: 950px) {
    font-size: 45%;
  }
}

a {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

`;

export default GlobalStyle;
