import React from "react";
import styled from "styled-components";
import GlobalStyle from "./helpers/GlobalStyle";
import Navigator from "./components/Navigator";
import { Route, Redirect } from "react-router-dom";
import InformationSection from "./components/InformationSection";

export default function App() {
  return (
    <GeneralContainer>
      <Route exact path="/" render={() => <Redirect to="/faq" />} />
      <GlobalStyle />
      <NavigatorContainer>
        <Route path={["/faq", "/privacy", "/terms"]} component={Navigator} />
      </NavigatorContainer>
      <Route
        path={["/faq", "/privacy", "/terms"]}
        component={InformationSection}
      />
    </GeneralContainer>
  );
}

const GeneralContainer = styled.div`
  padding: 5rem 20rem;
  display: flex;
  /* grid-template-columns: 2fr 3fr;
  grid-gap: 3rem; */
  justify-content: center;

  @media (max-width: 1400px) {
    padding: 5rem 10rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const NavigatorContainer = styled.nav`
  margin-right: 10rem;

  @media (max-width: 800px) {
    margin-right: 0px;
  }
`;
